import React from "react"
import { Link } from "gatsby"

import subHeaderStlyes from "./subheader.module.css"

const SubHeader = ({data}) => {
    const linkData = {
        news: {
            link: "/",
            linkText: "NEWS",
        },
        discord: {
            link: "https://discord.gg/vmxPBXcWR6",
            linkText: "DISCORD",
        },
        apply: {
            link: "https://docs.google.com/forms/d/1Xry9dtBbREeMZDkR5cq6p45E2mFLWqwFEHoenSTrFoU/edit",
            linkText: "APPLY"
        },
        contact: {
            link: "mailto:[siteemailhere]",
            linkText: "CONTACT"
        }
    };

    return (
        <div className={`row ${subHeaderStlyes.containerstrip}`}>
            <SubHeaderInsideButton  data={linkData.news} />
            <SubHeaderOutsideButton data={linkData.discord} />
            <SubHeaderOutsideButton data={linkData.apply} />
            <SubHeaderOutsideButton data={linkData.contact} />
        </div>
    )
}

const SubHeaderOutsideButton = ({data}) => {
    return (
        <div class="three columns">
            <a className={`button u-full-width ${subHeaderStlyes.buttonColor}`} href={data.link}>
                <h5>
                    {data.linkText}
                </h5>
            </a>
        </div>
    )
}

const SubHeaderInsideButton = ({data}) => {
    return (
        <div class="three columns">
            <Link className={`button u-full-width ${subHeaderStlyes.buttonColorBold}`} to={data.link}>
                <h5>
                    {data.linkText}
                </h5>
            </Link>
        </div>
    )
}

export default SubHeader