import React from "react"

import { rhythm, scale } from "../utils/typography"
import { Link } from "gatsby"

import newsStyles from "./newsdock.module.css"

const NewsDock = ({data}) => {
    let body

    console.log("newdock data");
    console.log(data);

    //need to check the data to see if it's an array
    if (data !== null && Array.isArray(data))
    {
      body = (
        <div>
          {data.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            const link = getYearMonthPathFromDate(node.frontmatter.date) + node.fields.slug
            return (
              <article key={node.fields.slug}>
                <header>
                  <h3
                    style={{
                      marginBottom: rhythm(1 / 4),
                    }}
                  >
                    <Link style={{ boxShadow: `none` }} to={`/${link}`}>
                      {title}
                    </Link>
                  </h3>
                  <small>{node.frontmatter.date}</small>
                </header>
                <section>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: node.frontmatter.description || node.excerpt,
                    }}
                  />
                </section>
              </article>
            )
          })}
        </div>
      )
    }
    else if (data !== null && data.hasOwnProperty("frontmatter"))
    {
        body = (
            <article>
            <header>
              <h1
                style={{
                  marginTop: rhythm(1),
                  marginBottom: 0,
                }}
              >
                {data.frontmatter.title}
              </h1>
              <p
                style={{
                  ...scale(-1 / 5),
                  display: `block`,
                  marginBottom: rhythm(1),
                }}
              >
                {data.frontmatter.date}
              </p>
            </header>
            <section dangerouslySetInnerHTML={{ __html: data.html }} />
            <hr
              style={{
                marginBottom: rhythm(1),
              }}
            />
            <footer>
              {/* <Bio /> */}
            </footer>
          </article>
        )
    }
    else{
        body = (
            <div>
                <h1>No news available</h1>
                <p>No news is available at this time...</p>
            </div>
        )
    }

    return (
        <div className={`nine columns ${newsStyles.newswell}`}>
            {body}
        </div>
    )
}

function getYearMonthPathFromDate(date)
{
  var splstr = date.split(" ")
  return splstr[2] + "/" + splstr[0]
}

export default NewsDock