import React from "react"

import headerStyles from "./biijheader.module.css"

const BiijHeader = () => {
    return (
        <div class="row">
            <div class="four columns u-max-full-width">
                <img src="/demo.png" />
            </div>
            <div className={`eight columns ${headerStyles.headerwell}`}>
                <h1>Space Cats on EE</h1>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ante massa, consequat ut elementum vitae, congue at erat. Suspendisse finibus mauris metus, quis auctor sem consequat eget. Maecenas pulvinar nisl in vehicula maximus. Nunc eget mattis nibh. Quisque efficitur tortor ipsum, in faucibus ante eleifend ut. Suspendisse vel sapien sed tellus ultricies consequat. Pellentesque at urna sagittis, dictum tortor non, tincidunt metus. Donec facilisis id lacus quis rutrum. Cras non turpis et diam vehicula venenatis et nec eros.
                </p>
            </div>
        </div>
    )
}

export default BiijHeader