import React from "react"
import { Link } from "gatsby"

import { rhythm, scale } from "../utils/typography"


import BiijHeader from "../components/biijheader"
import NewsDock from "../components/newsdock"
import NewsSideBar from "../components/newssidebar"
import SubHeader from "../components/subheader"

import layoutStyles from "./layout.module.css"


const Layout = ({location, data}) => {
    const rootPath = `${__PATH_PREFIX__}/`
    let newsDock
    if (Array.isArray(data)) {
      newsDock = (
        <NewsDock data={data}></NewsDock>
      )
    }
    else {
      newsDock = (
        <NewsDock data={data.node}></NewsDock>
      )
    }
    return (
      <div className={`container ${layoutStyles.mainwell}`}>
        <BiijHeader></BiijHeader>
        <SubHeader></SubHeader>
      <div class="row">
        {newsDock}
        <NewsSideBar></NewsSideBar>
      </div>
    </div>
    )
}

// const Layout = ({ location, title, children }) => {
//   const rootPath = `${__PATH_PREFIX__}/`
//   let header

//   if (location.pathname === rootPath) {
//     header = (
//       <h1
//         style={{
//           ...scale(1.5),
//           marginBottom: rhythm(1.5),
//           marginTop: 0,
//         }}
//       >
//         <Link
//           style={{
//             boxShadow: `none`,
//             color: `inherit`,
//           }}
//           to={`/`}
//         >
//           {title}
//         </Link>
//       </h1>
//     )
//   } else {
//     header = (
//       <h3
//         style={{
//           fontFamily: `Montserrat, sans-serif`,
//           marginTop: 0,
//         }}
//       >
//         <Link
//           style={{
//             boxShadow: `none`,
//             color: `inherit`,
//           }}
//           to={`/`}
//         >
//           {title}
//         </Link>
//       </h3>
//     )
//   }
//   return (
//     <div
//       style={{
//         marginLeft: `auto`,
//         marginRight: `auto`,
//         maxWidth: rhythm(24),
//         padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
//       }}
//     >
//       <header>{header}</header>
//       <main>{children}</main>
//       <footer>
//         © {new Date().getFullYear()}, Built with
//         {` `}
//         <a href="https://www.gatsbyjs.org">Gatsby</a>
//       </footer>
//     </div>
//   )
// }

export default Layout
