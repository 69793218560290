import React from "react"
import { Link, useStaticQuery, graphql } from 'gatsby'

import newsStyles from "./newssidebar.module.css"

export default function NewsSideBar() {
    //assumption time: the dates coming in are in MMMM DD, YYYY format
    //we just care about MMMM and YYYY
    const posts = useStaticQuery(graphql`
    query MyQuery {
        allMarkdownRemark {
            edges {
                node {
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                    }
                }
            }
        }
    }`);

    const monthyears = groupByMonthYear(posts.allMarkdownRemark.edges);
    return (
        <div className={`three columns ${newsStyles.sidebar}`}>
            <ul>
                {Object.keys(monthyears).map(my => (
                    <li>
                        <Link to={`/${monthyears[my].year}`}>
                            <h4 className={newsStyles.sidebarlink}>{monthyears[my].year}</h4>
                        </Link>
                        <ul className={newsStyles.innersidebar}>
                            {Object.keys(monthyears[my].months).map(month => (
                                <li>
                                    <Link to={`/${monthyears[my].year + "/" + monthyears[my].months[month]}`}>
                                        {monthyears[my].months[month]}
                                    </ Link>
                                </li>
                            ))}
                        </ul>
                    </li>
                ))}
            </ul>
        </div>
    )
}

function groupByMonthYear(posts) {
    var response = {};
    Object.keys(posts).forEach(node => {
        var date = posts[node].node.frontmatter.date;
        var strsplit = date.split(" ");
        var year = strsplit[2];
        var month = strsplit[0];


        if (!response[year])
        {
            response[year] = {
                year: year,
                months: {}
            }
        }

        if (!response[year]["months"][month])
        {
            response[year]["months"][month] = month;
        }
    })
    return response;
}

function numberToMonth(number)
{
    switch (number)
    {
        case "01":
            return "January";
        case "02":
            return "February";
        case "03":
            return "March";
        case "04":
            return "April";
        case "05":
            return "May";
        case "06":
            return "June";
        case "07":
            return "July";
        case "08":
            return "August";
        case "09":
            return "September";
        case "10":
            return "October";
        case "11":
            return "November";
        case "12":
            return "December";
        default:
            return "January";
    }
}
